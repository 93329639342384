import Swiper from "swiper";
import {Navigation} from "swiper/modules";
import ModalVideo from "modal-video/lib/core";


document.addEventListener('turbolinks:load', () => {
    $('[data-toggle="modal"]').on('click', function(e) {
        e.preventDefault()
        const targetId  = $(this).data('target')
        $(targetId).addClass('modal-show').trigger('visibilitychange')
    })

    $(document).on('click', '.modal', (e) => {
        const target = $(e.target)
        const isTargetModal = target.hasClass('modal-show')
        if(isTargetModal) {
            target.removeClass('modal-show')
        }
    })
    $('.modal .btn-cancel').on('click', function (e) {
        e.preventDefault()
        const target = $(this)
        target.parents('.modal').removeClass('modal-show')
    })

    $('.has-sub-menu').on('click', (e) => {
        const link = $(e.target)
        const parent = link.parents('.has-sub-menu')
        const subMenu = parent.find('.sub-menu')
        parent.toggleClass('show-menu')
        if(parent.hasClass('show-menu')) {
            subMenu.slideDown(100)
        } else {
            subMenu.slideUp()
        }
    })

    const accItem = document.querySelectorAll(".acc-item");
    if(accItem.length> 0) {
        accItem.forEach((item, index) => {
            let accHeader = item.querySelector(".acc-item_header");
            accHeader.addEventListener("click", () => {
                let accBody = item.querySelector(".acc-item_body");
                if (!item.classList.contains("acc-item_active")) {
                    item.classList.add("acc-item_active");
                    accBody.style.maxHeight = accBody.scrollHeight + 32 + "px";
                    accItem.forEach((element, ind) => {
                        let elementBody = element.querySelector(".acc-item_body");
                        if (!(ind === index)) {
                            element.classList.remove("acc-item_active");
                            elementBody.style.maxHeight = null;
                        }
                    });
                } else {
                    item.classList.remove("acc-item_active");
                    accBody.style.maxHeight = null;
                }
            });
        });
    }

    if($('.js-modal-play').length > 0) {
        new ModalVideo('.js-modal-play')
    }

    $(document).on('click', '.modal-close',function() {
        $('.modal').removeClass('modal-show')
    })

    let swiper = null
    $(document).on('click', '.icon-zoom', function () {
        const btn = $(this)
        const index = btn.data('index')

        if($('.image-preview-modal').length > 0) {
            $('.image-preview-modal').addClass('modal-show')
            swiper?.slideTo(index);
            return
        }

        let swiperSlide = ''
        const parent = btn.parents('.table')
        const images = parent.find('.patient-image')

        $.each(images, (i, img) => {
            swiperSlide += `<div class="swiper-slide"><img class="radius-8 image m-auto" src="${$(img).attr('src')}"></div>`
        })
        const modalContent = `
            <div class="modal-cntr modal-cntr_lg radius-20">
                <div class="modal-close"></div>
                <div class="modal-body">
                    <div class="swiper image-preview-slider">
                        <div class="swiper-wrapper">
                            ${swiperSlide}
                        </div>
                        <div class="swiper-button-prev"></div>
                        <div class="swiper-button-next"></div>
                    </div>
                </div>
            </div>
        `

        const modal = document.createElement('div')
        modal.classList.add('modal','modal-show','image-preview-modal')
        modal.innerHTML = modalContent
        $('body').append(modal)

        swiper = new Swiper(".image-preview-slider", {
            modules: [Navigation,],
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });
    });

    $('.patient-assessment-table_container').on('scroll', function (e) {
        $('.top-scrollbar').scrollLeft($(this).scrollLeft());
    });
    $('.top-scrollbar').on('scroll', function (e) {
        $('.patient-assessment-table_container').scrollLeft($(this).scrollLeft());
    });

})
